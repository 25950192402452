import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const StyledImage = styled.div`
  float: left;
  margin: 10px;
  text-align: center;
  width: 30%;

  &:hover {
    cursor: ${props => (props.noZoom ? 'default' : 'zoom-in')};
  }

  @media (max-width: 1100px) {
    width: 50%;
    margin: 10px 0;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    width: 90%;
  }

  img {
    border-radius: 2px;
  }
`

interface IModalProps {
  visible: boolean
}

const Modal = styled<IModalProps, any>('div')`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;

  .gatsby-image-wrapper {
    width: 75%;
    border-radius: 4px;
    overflow: hidden;
    max-width: 900px;

    @media (max-width: 860px) {
      width: 80%;
    }
    @media (max-width: 520px) {
      width: 95%;
    }
  }
`

const BG = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
`

interface IProps {
  modal: object
  noModal: boolean
}

interface IState {
  modalVisible: boolean
}

class GalleryImage extends React.Component<IProps, IState> {
  state = {
    modalVisible: false
  }

  render() {
    return (
      <>
        <StyledImage
          noZoom={this.props.noModal}
          onClick={() => {
            console.log('clicked')
            if (this.props.noModal) {
              return null
            }
            this.setState({ modalVisible: true }, () => {

              console.log(this.state.modalVisible, 'modalVisible')
            })
          }}
        >
          {this.props.children}
        </StyledImage>
        {this.state.modalVisible && (
          <Modal visible={this.state.modalVisible}>
            <BG
              onClick={() => {
                this.setState({ modalVisible: false })
              }}
            />
            <Img fluid={this.props.modal} />
          </Modal>
        )}
      </>
    )
  }
}

export default GalleryImage
